import React from "react";
import LoginCheckout from "./Components/LoginCheckout";

function Checkout() {
  return (
    <div>
      <LoginCheckout />
    </div>
  );
}

export default Checkout;
