export const QUERY_CITY_REQUEST = "QUERY_CITY_REQUEST";
export const QUERY_CITY_SUCCESS = "QUERY_CITY_SUCCESS";
export const QUERY_CITY_FAILURE = "QUERY_CITY_FAILURE";

export const GET_USER_LOCATION_REQUEST = "GET_USER_LOCATION_REQUEST";
export const GET_USER_LOCATION_SUCCESS = "GET_USER_LOCATION_SUCCESS";
export const GET_USER_LOCATION_FAILURE = "GET_USER_LOCATION_FAILURE";

export const USER_SIGNUP_REQUEST = "USER_SIGNUP_REQUEST";
export const USER_SIGNUP_SUCCESS = "USER_SIGNUP_SUCCESS";
export const USER_SIGNUP_FAILURE = "USER_SIGNUP_FAILURE";

export const USER_SIGNUP_VERIFY_REQUEST = "USER_SIGNUP_VERIFY_REQUEST";
export const USER_SIGNUP_VERIFY_SUCCESS = "USER_SIGNUP_VERIFY_SUCCESS";
export const USER_SIGNUP_VERIFY_FAILURE = "USER_SIGNUP_VERIFY_FAILURE";

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";

export const USER_LOGIN_VERIFY_REQUEST = "USER_LOGIN_VERIFY_REQUEST";
export const USER_LOGIN_VERIFY_SUCCESS = "USER_LOGIN_VERIFY_SUCCESS";
export const USER_LOGIN_VERIFY_FAILURE = "USER_LOGIN_VERIFY_FAILURE";

export const USER_LOGIN_GOOGLE_REQUEST = "USER_LOGIN_GOOGLE_REQUEST";
export const USER_LOGIN_GOOGLE_SUCCESS = "USER_LOGIN_GOOGLE_SUCCESS";
export const USER_LOGIN_GOOGLE_FAILURE = "USER_LOGIN_GOOGLE_FAILURE";

export const SET_SEARCH_CITY = "SET_SEARCH_CITY";

export const GET_CITY_ID_REQUEST = "GET_CITY_ID_REQUEST";
export const GET_CITY_ID_SUCCESS = "GET_CITY_ID_SUCCESS";
export const GET_CITY_ID_FAILURE = "GET_CITY_ID_FAILURE";

export const GET_CITY_COLLECTION_REQUEST = "GET_CITY_COLLECTION_REQUEST";
export const GET_CITY_COLLECTION_SUCCESS = "GET_CITY_COLLECTION_SUCCESS";
export const GET_CITY_COLLECTION_FAILURE = "GET_CITY_COLLECTION_FAILURE";

export const QUERY_RESTAURANT_REQUEST = "QUERY_RESTAURANT_REQUEST";
export const QUERY_RESTAURANT_SUCCESS = "QUERY_RESTAURANT_SUCCESS";
export const QUERY_RESTAURANT_FAILURE = "QUERY_RESTAURANT_FAILURE";

export const GET_CITY_LOCALITIES_REQUEST = "GET_CITY_LOCALITIES_REQUEST";
export const GET_CITY_LOCALITIES_SUCCESS = "GET_CITY_LOCALITIES_SUCCESS";
export const GET_CITY_LOCALITIES_FAILURE = "GET_CITY_LOCALITIES_FAILURE";

export const USER_LOGOUT = "USER_LOGOUT";
